import React, { Fragment } from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import { CircularProgress } from "../../../components";

const NotFound = () => (
  <Fragment>
    <div className="api-loading">
      <CircularProgress />
    </div>
  </Fragment>
);

export default NotFound;

const NotFound2 = () => (
  <Fragment>
    <div className="api-loading">
      <img
        alt="No data available"
        style={{ width: "25%" }}
        src={require("assets/images/loading.gif")}
      />
    </div>
  </Fragment>
);
