import React from "react";
import {
  Link,
  connect,
  TEXTFIELD,
  CircularProgress,
  toast,
  ToastContainer,
  Logo,
  BackGroundImage,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  Row,
  Col,
  ApiUrl,
  Loading,
} from "../../components";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
const { API_LOGIN } = ApiUrl;

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, password } = this.state;

    const formData = {
      email: email.trim(),
      password: password.trim(),
    };

    Axios.post(API_LOGIN, formData)
      .then((response) => {
        const data = response.data;

        if (data.status == "failure") {
          toast.error(data.message);
        } else {
          console.log(data);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("_id", data.data._id);
          localStorage.setItem("name", data.data.name);
          localStorage.setItem("role", data.data.role);
          localStorage.setItem("user_id", data.data.token);
          this.props.userSignInSuccess(data.data.token);
          toast.success("Login successfully.");
          setTimeout(() => {
            this.props.history.push("/app/dashboard/index");
          }, 1000);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        console.log(err);
        let error = err.response;
        if (error !== undefined) {
          toast.error(error.data.message);
        } else {
          toast.error("server error, Please try after some time.");
        }
        this.props.hideAuthLoader();
      });
  };

  render() {
    const { email, password } = this.state;
    const { loader } = this.props;
    var diabled = !email || !password ? true : "";
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny">
        <div className="app-login-main-content">
          <Row style={{ height: 325 }}>
            <Col md={6} xs={12}>
              <img
                src={Logo}
                alt="jambo"
                title="jambo"
                className="logo"
              />
            </Col>
            <Col md={6} xs={12}>
              <form
                onSubmit={(e) => this.loginUser(e)}
                className="outside-pages"
              >
                <Row>
                  <Col md={12} xs={12} className="text-center">
                    <img
                      src={Logo}
                      alt="jambo"
                      title="jambo"
                      style={{ width: 75 }}
                    />
                  </Col>
                  <Col md={12} xs={12}>
                    <h4>Login</h4>
                  </Col>
                  <Col md={12} xs={12}>
                    <TEXTFIELD
                      placeholder="Email/Phone no."
                      fullWidth
                      name="email"
                      onChange={this.handleChange}
                      value={email}
                      errors={{}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PermIdentityIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>

                  <Col md={12} xs={12}>
                    <TEXTFIELD
                      type="password"
                      placeholder="Password"
                      fullWidth
                      name="password"
                      onChange={this.handleChange}
                      value={password}
                      errors={{}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOpenIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                  {/* <Col md={12} xs={12} className="link-block">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </Col> */}
                  <Col xm="12" md="12" className="text-center">
                    <SUBMIT title="Submit" disabled={diabled} style={{ background: "#2c98d5", olor: "white" }} />
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
        {loader && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(SignIn);
