import React from 'react';
import {Redirect, Route, Switch,asyncComponent} from '../../../components';
const PushNotification = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/basic`}/>
      <Route path={`${match.url}/basic`} component={asyncComponent(() => import('./routes/basic'))}/>
      <Route path={`${match.url}/installation`} component={asyncComponent(() => import('./routes/installation'))}/>
      <Route path={`${match.url}/password-change`} component={asyncComponent(() => import('./routes/password'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default PushNotification;
