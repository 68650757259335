import React from "react";
import {
  Link,
  connect,
  TEXTFIELD,
  CircularProgress,
  toast,
  ToastContainer,
  Logo,
  Axios,
  PASSWORDFIELD,
  SUBMIT,
  Row,
  Col,
  BackGroundImage,
  Loading,
  ApiUrl,
} from "../../../components";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";
const {
  API_FORGOT_PASSWORD,
  API_VERIFY_OTP,
  API_RESEND_OTP,
  API_RESET_PASSWORD,
} = ApiUrl;

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      otp: "",
      password: "",
      confirmPassword: "",
      emailForm: true,
      otpForm: false,
      resetForm: false,
      errorMessage: "",
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  forgotPassword = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, password } = this.state;

    const formData = {
      email: email.trim(),
    };

    Axios.post(API_FORGOT_PASSWORD, formData)
      .then((response) => {
        const { data, status, message } = response.data;

        if (status == "failure") {
          toast.error(message);
        } else {
          toast.success(message);
          this.setState({ emailForm: false, otpForm: true });
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        this.errorAction(err);
      });
  };
  verifyEmail = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, otp } = this.state;

    const formData = {
      email: email.trim(),
      OTP: otp.trim(),
    };

    Axios.post(API_VERIFY_OTP, formData)
      .then((response) => {
        const { data, status, message } = response.data;

        if (status == "failure") {
          toast.error(message);
        } else {
          toast.success(message);
          this.setState({ resetForm: true, otpForm: false });
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        this.errorAction(err);
      });
  };

  resedOtp = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, otp } = this.state;
    const formData = {
      email: email.trim(),
    };

    Axios.post(API_RESEND_OTP, formData)
      .then((response) => {
        const { data, status, message } = response.data;

        if (status == "failure") {
          toast.error(message);
        } else {
          toast.success(message);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        this.errorAction(err);
      });
  };

  resetPassword = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, otp, password, confirmPassword } = this.state;
    const formData = {
      email: email.trim(),
      OTP: otp.trim(),
      password: password.trim(),
      confirmPassword: confirmPassword.trim(),
    };

    Axios.post(API_RESET_PASSWORD, formData)
      .then((response) => {
        const { data, status, message } = response.data;
        if (status == "failure") {
          toast.error(message);
        } else {
          toast.success(message);
          setTimeout(() => {
            this.props.history.push("/signin");
          }, 1000);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        this.errorAction(err);
      });
  };

  errorAction = (err) => {
    let error = err.response;
    if (error !== undefined) {
      toast.error(error.data.message);
    } else {
      toast.error("server error, Please try after some time.");
    }
    this.props.hideAuthLoader();
  };

  render() {
    const {
      email,
      otp,
      password,
      confirmPassword,
      emailForm,
      otpForm,
      resetForm,
    } = this.state;
    const { loader } = this.props;
    var diabled = !email ? true : "";
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny">
        <div className="app-login-main-content">
          <Row style={{ height: 400 }}>
            <Col md={6} xs={12}>
              <img
                src={BackGroundImage}
                alt="jambo"
                title="jambo"
                className="logo"
              />
            </Col>
            {emailForm && (
              <Col md={6} xs={12}>
                <form
                  onSubmit={(e) => this.forgotPassword(e)}
                  className="outside-pages"
                >
                  <Row>
                    <Col md={12} xs={12} className="text-center">
                      <img
                        src={Logo}
                        alt="jambo"
                        title="jambo"
                        style={{ width: 75 }}
                      />
                    </Col>
                    <Col md={12} xs={12}>
                      <h4>Forgot Password</h4>
                    </Col>
                    <Col md={12} xs={12}>
                      <TEXTFIELD
                        placeholder="Email/Phone no."
                        fullWidth
                        name="email"
                        onChange={this.handleChange}
                        value={email}
                        errors={{}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PermIdentityIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>

                    <Col xm="12" md="12" className="text-center">
                      <SUBMIT title="Submit" disabled={diabled} />
                    </Col>
                    <Col md={12} xs={12} className="link-block">
                      <p>A reset code will be sent to your email.</p>
                    </Col>
                  </Row>
                </form>
              </Col>
            )}
            {otpForm && (
              <Col md={6} xs={12}>
                <form
                  onSubmit={(e) => this.verifyEmail(e)}
                  className="outside-pages"
                >
                  <Row className="d-flex justify-content-center">
                    <Col md={12} xs={12} className="text-center">
                      <img
                        src={Logo}
                        alt="jambo"
                        title="jambo"
                        style={{ width: 75 }}
                      />
                    </Col>
                    <Col md={12} xs={12}>
                      <h4>Email Verification</h4>
                      <p>
                        Verification code has been sent to email or your mobile
                      </p>
                    </Col>
                    <Col md={5} xs={5}>
                      <TEXTFIELD
                        type="password"
                        placeholder="OTP"
                        fullWidth
                        name="otp"
                        onChange={this.handleChange}
                        value={otp}
                        errors={{}}
                      />
                    </Col>

                    <Col xm="12" md="12" className="text-center">
                      <SUBMIT title="Submit" disabled={diabled} />
                    </Col>
                    <Col md={12} xs={12} className="link-block">
                      <p>Your email/Sms should arrive within 58 seconds(s)</p>
                    </Col>
                    <Col md={12} xs={12} className="link-block">
                      <Link
                        to="/"
                        onClick={(e) => {
                          this.resedOtp(e);
                        }}
                      >
                        Resend OTP
                      </Link>
                    </Col>
                  </Row>
                </form>
              </Col>
            )}
            {resetForm && (
              <Col md={6} xs={12}>
                <form
                  onSubmit={(e) => this.resetPassword(e)}
                  className="outside-pages"
                >
                  <Row className="d-flex justify-content-center">
                    <Col md={12} xs={12} className="text-center">
                      <img
                        src={Logo}
                        alt="jambo"
                        title="jambo"
                        style={{ width: 75 }}
                      />
                    </Col>
                    <Col md={12} xs={12}>
                      <h4>Reset Password</h4>
                    </Col>
                    <Col md={12} xs={12}>
                      <TEXTFIELD
                        type="password"
                        placeholder="New Password"
                        fullWidth
                        name="password"
                        onChange={this.handleChange}
                        value={password}
                        errors={{}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOpenIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>
                    <Col md={12} xs={12}>
                      <TEXTFIELD
                        type="password"
                        placeholder="Confirm Password"
                        fullWidth
                        name="confirmPassword"
                        onChange={this.handleChange}
                        value={confirmPassword}
                        errors={{}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOpenIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>

                    <Col xm="12" md="12" className="text-center">
                      <SUBMIT title="Save Password" disabled={diabled} />
                    </Col>
                  </Row>
                </form>
              </Col>
            )}
          </Row>
        </div>
        {loader && (
          <div className="text-center w-100">
            <Loading />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(SignIn);
