import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withRouter } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const getDisplayString = (sub) => {
  const arr = sub.split("-");
  if (arr.length > 1) {
    return (
      arr[0].charAt(0).toUpperCase() +
      arr[0].slice(1) +
      " " +
      arr[1].charAt(0).toUpperCase() +
      arr[1].slice(1)
    );
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  }
};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return "/";
  } else {
    return "/" + path.split(sub)[0] + sub;
  }
};

const ContainerHeader = ({ title, id, icon = "", match }) => {
  const path = match.path.substr(1);
  const subPath = path.split("/");
  // console.log(this.props,6666666666666)
  function goBack() {
    window.history.back();
  }
  return (
    <div className="d-sm-flex justify-content-sm-between align-items-sm-center">
      <h3 className="title">
        {/*<Tooltip title="Back" placement="left">
        <ArrowBackIosIcon style={{fontSize: 'smaller',fontWeight: 'bold',cursor: 'pointer'}} onClick={() => goBack()} />
        </Tooltip>*/}
        {/* <i class={`zmdi ${icon} zmdi-hc-fw`}></i> */}

        {title}
      </h3>
      {id && <h6 className="title mb-3 mb-sm-0"># {id}</h6>}
    </div>
  );
};

export default withRouter(ContainerHeader);
