const Status={
    'created':"Registered",
    "registered":"Registered",
    'approved':"Approved",
    'rejected':"Rejected",
    'Online':'Online',
    'Offline':'Offline',
    true:"Yes",
    false:"No",
    "yes":"Yes",
    "no":"No",
    "approved":"Approved",
    'created':"New",
    'rejected':"Rejected",
    'active':"Active",
    'inactive':'In Active',
    'Cancelled':'Cancelled',
    'Completed':'Completed',
    'Scheduled':'Scheduled',
    'FindingDrivers':'Finding',
     'WatingForDriver':'Wating',
    'FindingDrivers':'Finding',
    'Arrived':'Arrived',
    'pending':'Pending',
    'blocked': 'Blocked',
    // 'Arrived': 'Arrived',
}
export default Status;
