// export const API_URL_LIVE = "https://directoarnedic-api.ondemandcreations.com";
// export const API_URL_STAGGING =
//   "https://directoarnedic-api.ondemandcreations.com";

export const API_URL_LIVE = "https://api.directparamed.com";
export const API_URL_STAGGING = "https://api.directparamed.com";

export const API_URL =
  process.env.REACT_APP_MODE === "live" ? API_URL_LIVE : API_URL_STAGGING;

export const API_VERSION = "/api/v1";
export const APP_NAME = "Direct Paramedic";
export const PANEL_NAME = "Admin";

// COMMON CONSTANT
export const API_TOKEN = localStorage.getItem("user_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const Numeric = /^[0-9]+$/;
export const ALPHABET = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.";

// AUTH URL
export const API_LOGIN = "/admin/login";
export const API_FORGOT_PASSWORD = "/admin/forgotpassword";
export const API_VERIFY_OTP = "/admin/verifyotp";
export const API_RESEND_OTP = "/admin/resendotp";
export const API_RESET_PASSWORD = "/admin/resetpassword";

// DASHBOARD
export const DASHBOARD = "/admin/dashboard/getDashboardList";

// REVENUE
export const API_GET_REVENUE = "/adminDashboard/revenueByMonthDay";

// Stores
export const API_GET_STORES_URL = "/store/";
export const API_GET_STORES_BY_ID = "/store/getstorebyId/";
export const API_ADD_STORES = "/store/register/";
export const API_EDIT_STORES = "/store/updatestore/";
export const API_ADD_STORE_BANKAC = "/store/addaccount/";
export const API_ADD_STORE_DOCS = "/store/addStoreDoc/";
export const API_GET_STORE_DOCS = "/store/getStoreDoc/";

// // Store's Categories
// export const API_GET_STORES_CATEGORIES = '/storecategory/';
// export const API_GET_STORES_CATEGORIE_BY_ID = '/storecategory/edit/';
// export const API_ADD_STORES_CATEGORIE = '/storecategory/add/';
// export const API_EDIT_STORES_CATEGORIE = '/storecategory/update';
// export const API_DELETE_STORES_CATEGORIE = '/storecategory/remove';

// // Store's Items
// export const API_GET_STORES_ITEMS = '/store/getAllStoreItem/';
// export const API_GET_STORES_ITEM_BY_ID = '/store/getItemById/';
// export const API_ADD_STORES_ITEM = '/store/addStoreItem/';
// export const API_EDIT_STORES_ITEM = '/store/updateStoreItem';
// export const API_DELETE_STORES_ITEM = '/store/deleteStoreItem';
// export const API_UPDATE_STORE_ITEM_STATUS = '/store/updateItemStatus';

// Drivers
export const API_GET_DRIVER_URL = "/admin/driver/driversList/";
export const API_GET_DRIVER = "/admin/driver/getDriverById/";
export const API_ADD_DRIVER = "/driver/register/";
export const API_EDIT_DRIVER = "/admin/driver/updateDriver/";
export const API_DELETE_DRIVER = "/admin/driver/deleteDriver/";
export const API_STATUS_CHANGE_RIDER = "/admin/service-provider/statusChange/";
export const API_BLOCK_RIDER = "/admin/driver/blockUnblock/";
export const API_DRIVER_PAYMENTS =
  "/admin/driver/getAllDriverTransactionsById/";
export const API_DRIVER_TRIPS = "/admin/driver/getDriverTripsData/";

// Ride Slab
export const API_GET_RIDE_SLABS = "/admin/driver/getAllSubscription/";
export const API_ADD_RIDE_SLAB = "/admin/driver/addSubscription/";
export const API_EDIT_RIDE_SLAB = "/admin/driver/updateSubscription/";
export const API_DELETE_RIDE_SLAB = "/admin/driver/deleteAllSubscription/";
export const API_GET_RIDE_SLAB_BY_ID = "/admin/driver/getSubscriptionById/";

export const API_GET_LABS_URL = "/admin/service-provider/list/";
export const API_GET_LAB = "/admin/service-provider/getById/";
export const API_ADD_LABS = "/admin/service-provider/add/";
export const API_EDIT_LABS = "/admin/service-provider/edit/";
export const API_DELETE_LABS = "/admin/service-provider/delete/";
export const API_BLOCK_LABS = "/admin/service-provider/blockUnblock/";
export const API_LABS_PAYMENTS =
  "/admin/service-provider/getAllDriverTransactionsById/";

// Event Urls
export const API_GET_EVENTS = "/admin/customers/getEventList/";
export const API_GET_EVENT_DETAILS = "/admin/customers/getEventById/";
export const API_DELETE_EVENTS = "/admin/customers/removeEvent";

// Customers URLs
export const API_GET_CUSTOMERS_URL = "/admin/customers/list";
export const API_GET_CUSTOMER = "/admin/customers/getById/";
export const API_ADD_CUSTOMER = "/admin/customers/add";
export const API_EDIT_CUSTOMER = "/admin/customers/edit";
export const API_DELETE_CUSTOMER = "/admin/customers/delete/";
export const API_BLOCK_CUSTOMER = "/admin/customers/block";
export const API_CUSTOMER_TRIPS = "/admin/customers/list";
export const API_CUSTOMER_INVOICE = "/admin/customers/list";

/// Lab Test categories URLs
export const API_GET_LAB_CATEGORIES = "/admin/lab-category/list-filters";
export const API_GET_ALL_LAB_CATEGORIES = "/admin/lab-category/list";
export const API_GET_LAB_CATEGORY = "/admin/lab-category/getbyid/";
export const API_CHANGE_LAB_CATEGORY_STATUS =
  "/admin/lab-category/statusChange";
export const API_ADD_LAB_CATEGORY = "/admin/lab-category/add";
export const API_EDIT_LAB_CATEGORY = "/admin/lab-category/edit";
export const API_DELETE_LAB_CATEGORY = "/admin/lab-category/delete/";

// Lab Test categories URLs
export const API_GET_LAB_TESTS = "/admin/lab-test/list-filters";
export const API_GET_LAB_TEST = "/admin/lab-test/getbyid/";
export const API_ADD_LAB_TEST = "/admin/lab-test/add";
export const API_EDIT_LAB_TEST = "/admin/lab-test/edit";
export const API_DELETE_LAB_TEST = "/admin/lab-test/delete/";

// Dispatcher Urls
export const API_ADD_DISPATCHER = "/dispatcher/register";

export const API_GET_DISPATCHER = "/dispatcher/dispatcherById/";
export const API_EDIT_DISPATCHER = "/dispatcher/updateprofile";
export const API_GET_DISPATCHERS_URL = "/dispatcher/";
export const API_DELETE_DISPATCHER = "/dispatcher/remove";

// Dispatcher Urls
export const API_ADD_HOTEL = "/hotel/register";
export const API_GET_HOTEL = "/hotel/hotelById/";
export const API_EDIT_HOTEL = "/hotel/updateprofile";
export const API_GET_HOTELS_URL = "/hotel/";
export const API_DELETE_HOTEL = "/hotel/remove";

//Admin Setting URLs
export const API_GET_ALL_ADMINS = "/admin/list/";
export const API_EDIT_ADMIN = "/admin/edit/";
export const API_ADD_ADMIN = "/admin/add/";
export const API_GET_ADMIN = "/admin/getById/";

// Content pages

export const API_GET_CONTENT_PAGES_URL = "/admin/contents/list/";
export const API_EDIT_CONTENT_PAGE = "/admin/contents/update";
export const API_GET_CONTENT_PAGE_BY_ID = "/admin/contents/getById/";
export const API_ADD_CONTENT_PAGE = "/admin/contents/add";

// Faq
export const API_GET_ALL_FAQ = "/admin/faq/list/";
export const API_ADD_FAQ = "/admin/faq/add/";
export const API_EDIT_FAQ = "/admin/faq/edit/";
export const API_GET_FAQ = "/admin/faq/getById/";
export const API_DELETE_FAQ = "/admin/faq/delete/";

// Reason
export const API_GET_ALL_REASON = "/admin/getAllReasons/";
export const API_ADD_REASON = "/admin/addReason/";
export const API_EDIT_REASON = "/admin/editReason/";
export const API_GET_REASON = "/admin/getReasonsById/";
export const API_DELETE_REASON = "/admin/deleteReason/";

// Contarct Documents
export const API_GET_DOCS = "/admin/alldoc";
export const API_UPLOAD_DOC = "/admin/uploaddoc";
export const API_DELETE_DOC = "/admin/removedoc";

///// Settings

//Mail Templates Url
export const API_GET_MAIL_TEMPLATES = "/admin/templates/mail/list/";
export const API_GET_MAIL_TEMPLATE = "/admin/templates/mail/getById/";
export const API_ADD_MAIL_TEMPLATE = "/admin/templates/mail/add/";
export const API_EDIT_MAIL_TEMPLATE = "/admin/templates/mail/edit/";

//Sms Templates Url
export const API_GET_SMS_TEMPLATES = "/admin/templates/sms/list/";
export const API_GET_SMS_TEMPLATE = "/admin/templates/sms/getById/";
export const API_ADD_SMS_TEMPLATE = "/admin/templates/sms/add/";
export const API_EDIT_SMS_TEMPLATE = "/admin/templates/sms/edit/";

// Car Types Urls
export const API_GET_ALL_CAR_TYPES = "/admin/car-types/list";
export const API_GET_CAR_TYPE_BY_ID = "/admin/car-types/getById/";
export const API_ADD_CAR_TYPE = "/admin/car-types/add";
export const API_EDIT_CAR_TYPE = "/admin/car-types/edit";
export const API_DELETE_CAR_TYPE = "/admin/car-types/delete/";

// Trips URLs
export const API_GET_TRIPS = "/admin/dashboard/allTripsWithFilter/";
export const API_GET_TRIP_BY_ID = "/admin/dashboard/getCompletedDeatailsById/";

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = "admin/promo-codes/getPromocodeList";
export const API_ADD_PROMOCODE = "/admin/promo-codes/addPromoCode";
export const API_EDIT_PROMOCODE = "/admin/promo-codes/editPromocode";
export const API_UPDATE_PROMOCODE_STATUS =
  "/admin/promo-codes/updatePromocodeStatus";
export const API_GET_PROMOCODE = "/admin/promo-codes/getPromocodeDetails/";
export const API_DELETE_PROMOCODE = "admin/promo-codes/promocodeDelete/";

// /api/v1/promo/deletepromocode  // delete promocode

//  /api/v1/promo/promocodedetail   // detail promocode

//  /api/v1/promo/promocodedetail

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "admin/sendNotification";
export const GET_ALL_DRIVERS = "admin/alldrivers";
export const GET_ALL_CUSTOMERS = "admin/allcustomers";

// Settings
export const API_UPDATE_ADMIN_PASSWORD = "/admin/setting/changePassword/";
export const API_GET_BASIC_SETTINGS = "/admin/setting/getBasicsettinginfo";
export const API_UPDATE_BASIC_SETTINGS = "/admin/setting/basicAppSetting";
export const API_UPDATE_NOTIFICATION_SETTINGS =
  "/admin/setting/notificationSettingUpdate/";
export const API_UPDATE_SOCIAL_SETTINGS = "/admin/setting/basicSocialSetting";
export const API_INSTALLATION_SETTINGS = "/admin/setting/getConfigInfo";
export const API_UPDATE_SMS_INSTALLATION_SETTINGS =
  "/admin/setting/twilioUpdate";
export const API_UPDATE_PAYMENT_INSTALLATION_SETTINGS =
  "/admin/setting/PaymentConfigUpdate";
export const API_UPDATE_MAILGUN_INSTALLATION_SETTINGS =
  "/admin/setting/MailGunConfigUpdate";
export const API_UPDATE_ANDROID_INSTALLATION_SETTINGS =
  "/admin/setting/AndroidAppUrlUpdate";
export const API_UPDATE_IOS_INSTALLATION_SETTINGS =
  "/admin/setting/IOSAppURLUpdate";
